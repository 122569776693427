<template>
  <div class="card tw-mt-0">
    <BasicTable
      :columns="columnsBarberService"
      :data="barberServices"
      :title_services="`Jadwal Layanan`"
      :create="true"
      :buttonStyle="false"
      @new="onNew"
    >
      <template #header>
        <h1 style="font-size: 18px">Layanan Pegawai</h1>
      </template>
      <template #column>
        <Column header="Aksi">
          <template #body="context">
            <a class="tw-mr-4" @click.prevent="edit(context.data)">
              <i class="pi pi-pencil tw-text-lg"></i>
            </a>
            <a @click.prevent="confirmDelete(context.data)">
              <i class="pi pi-trash tw-text-lg"></i>
            </a>
          </template>
        </Column>
      </template>
    </BasicTable>
  </div>

  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Tambah Layanan"
    :modal="true"
  >
    <div class="p-fluid">
      <form @submit.prevent="submitService(formData)" class="">
        <div class="field">
          <label for="salon" class="font-semibold"
            >Layanan<span class="required-label">*</span></label
          >
          <Dropdown
            v-model="formData.service_id"
            @change="
              changeService(
                salonServices.filter(
                  (item) => item.id === formData.service_id
                )[0]
              )
            "
            :options="salonServices"
            optionLabel="name"
            optionValue="id"
            placeholder="Pilih Layanan"
          >
          </Dropdown>
        </div>

        <div
          class="tw-p-3 tw-rounded-lg tw-mb-2"
          style="
            background-color: rgba(153, 198, 228, 0.3);
            color: rgba(0, 113, 188, 1);
            border: 1px solid rgba(153, 198, 228, 1);
          "
        >
          Harga terisi otomatis mengikuti tiap pegawai. Klik Ubah Harga untuk
          memperbarui
        </div>

        <div class="field tw-mb-2">
          <label for="salon" class="font-semibold"
            >Harga<span class="required-label">*</span></label
          >
          <InputNumber
            v-model="formData.price"
            placeholder="Harga"
            currency="IDR"
            locale="id-ID"
            :disabled="!checked"
          />
        </div>
        <div class="flex tw-justify-start">
          <InputSwitch v-model="checked" />
          <label for="salon" class="font-semibold tw-ml-2">Ubah Harga</label>
        </div>

        <div class="tw-w-full tw-flex justify-content-between tw-mt-3">
          <div class="tw-w-full tw-mr-3">
            <Button
              label="Batal"
              class="tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
              @click="dismissModal"
            />
          </div>
          <div class="tw-w-full tw-mr-3">
            <Button
              label="Simpan"
              class="p-button-info"
              type="submit"
              :disabled="!formData.price && !formData.service_id"
            />
          </div>
        </div>
      </form>
    </div>
  </Dialog>

  <Dialog
    header="Konfirmasi"
    v-model:visible="showConfirmDelete"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span>Apakah Anda yakin ingin melanjutkan?</span>
    </div>
    <template #footer>
      <Button
        label="Tidak"
        icon="pi pi-times"
        @click="showConfirmDelete = false"
        class="p-button-text"
      />
      <button
        class="p-button p-component tw-px-10"
        disabled
        v-if="loadingDelete"
      >
        <ProgressSpinner
          stroke-width="5"
          animation-duration="2s"
          style="width: 30px; height: 30px"
        />
      </button>
      <Button
        label="Ya"
        icon="pi pi-check"
        @click="deleteService(barberServiceTemp)"
        class="p-button"
        autofocus
        v-else
      />
    </template>
  </Dialog>
</template>

<script setup>
import BasicTable from "@/components/dashboard/BasicTable";
import { useBarberService } from "@/composables/barber";
import {
  defineProps,
  defineEmits,
  onMounted,
  ref,
  watch,
  onUnmounted,
} from "vue";
import { useSalonServiceDropdown } from "@/composables/salon_service";
import { useCommonUtils } from "@/composables/utils";
import { useToast } from "primevue/usetoast";

const props = defineProps({
  barberId: String,
  salonId: String,
  salonName: String,
});

const checked = ref(false);
const emit = defineEmits(["getBarberServices"]);

const formData = ref({});
const showDialog = ref(false);
const isEdit = ref(false);
const barberServiceId = ref(null);
const showConfirmDelete = ref(false);
const barberServiceTemp = ref(null);
const loadingDelete = ref(false);
const toast = useToast();
const isMobile = ref(window.innerWidth <= 768);

const {
  barberServices,
  getBarberService,
  create,
  update,
  deleteBarberService,
} = useBarberService(props.barberId);
const { salonServices, getSalonServiceDropdown } = useSalonServiceDropdown();
const { formatCurrency } = useCommonUtils();

const columnsBarberService = [
  { field: "no", header: "No" },
  { field: "service_name", header: "Layanan" },
  { field: "price", header: "Harga", process: (data) => formatCurrency(data) },
];

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
};

const onNew = () => {
  showDialog.value = true;
};

const changeService = (dataParams) => {
  const dataArray = Object.values(dataParams);
  if (dataArray.length > 0) {
    const firstItem = dataArray[5]; // Mengambil elemen price dari array

    // Mengakses nilai properti 'price' dari elemen ke lima
    const price = firstItem;
    formData.value.price = price;
  } else {
    console.error("Array kosong atau data tidak tersedia.");
  }
};

const edit = (data) => {
  isEdit.value = true;
  formData.value = {
    service_id: data.service_id,
    price: data.price,
    admin_fee: data.admin_fee,
  };
  barberServiceId.value = data.service_id;
  showDialog.value = true;
};

const dismissModal = () => {
  showDialog.value = false;
};

const submitService = async (data) => {
  if (data.price <= 0) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Harga harus lebih dari ${formatCurrency(0)}`,
      life: 3000,
    });
    return;
  } else {
    toast.add({
      severity: "success",
      summary: "Berhasil",
      detail: `Berhasil Menambahkan`,
      life: 3000,
    });
    // reset form
    resetForm();
  }

  if (isEdit.value) {
    let response = await update(props.barberId, barberServiceId.value, data);
    isEdit.value = false;
    barberServiceId.value = null;
    if (response.code === 200) {
      toast.add({
        severity: "success",
        summary: "Success!",
        detail: response.message,
        life: 3000,
      });
      // reset form
      resetForm();
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: response.message,
        life: 3000,
      });
    }
  } else {
    let response = await create(props.barberId, data);
    if (response.code === 200) {
      toast.add({
        severity: "success",
        summary: "Success!",
        detail: response.message,
        life: 3000,
      });
      // reset form
      resetForm();
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: response.message,
        life: 3000,
      });
    }
  }
  await getBarberService(props.salonName);
  dismissModal();
  formData.value = {};
  emit("getBarberServices", barberServices);
};

const confirmDelete = (data) => {
  showConfirmDelete.value = true;
  barberServiceTemp.value = data;
};

const deleteService = async (data) => {
  loadingDelete.value = true;

  let response = await deleteBarberService(data.id);

  if (response.status === 200) {
    toast.add({
      severity: "success",
      summary: "Success",
      detail: response.data.message,
      life: 3000,
    });

    await getBarberService(props.salonName);
    emit("getBarberServices", barberServices);
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: response.data.message,
      life: 3000,
    });
  }

  loadingDelete.value = false;
  showConfirmDelete.value = false;
};

const resetForm = () => {
  formData.value = {};
  checked.value = false;
};

watch(
  () => props.salonId,
  async (salonId) => {
    if (salonId) {
      await getSalonServiceDropdown({ salon_id: salonId });
    }
  }
);

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

onMounted(async () => {
  window.addEventListener("resize", handleResize);
  await getBarberService(props.salonName);
  emit("getBarberServices", barberServices);
});
</script>

<style scoped>
.required-label {
  color: red;
}
</style>
