import {ref} from "vue";
import SalonServiceService from "@/service/SalonServiceService";

export function useSalonServiceList() {
    const salonServices = ref([]);
    const totalSalonServices = ref(0);
    const salonServiceService = new SalonServiceService();

    const getSalonServices = async (query) => {
        const res = await salonServiceService.getAll(query)
        const data = res.data
        salonServices.value = data.salon_services
        totalSalonServices.value = data.total
    }

    return {salonServices, totalSalonServices, getSalonServices}
}

export function useSalonServiceDropdown() {
    const salonServices = ref([]);
    const selectedService = ref(null);
    const salonServiceService = new SalonServiceService();

    const getSalonServiceDropdown = async (query) => {
        const res = await salonServiceService.getDropdown(query)
        const data = res.data
        salonServices.value = data.salon_services
    }

    return {salonServices, selectedService, getSalonServiceDropdown}
}

export function useSalonServiceForm() {
    const salonServiceService = new SalonServiceService();

    const create = async (data) => {
        const formData = new FormData()
        Object.keys(data).forEach(item => {
            formData.append(item, data[item])
        })
        await salonServiceService.create(formData)
    }

    const update = async (id, data) => {
        return await salonServiceService.update(id, data)
    }

    const updateImage = async (id, image) => {
        const formData = new FormData()
        formData.append('image', image)
        await salonServiceService.uploadImage(id, formData)
    }

    return {create, update, updateImage}
}

export function useSalonServiceDetail() {
    const detail = ref({})
    const salonServiceService = new SalonServiceService();

    const getDetail = async (id) => {
        const res = await salonServiceService.getOne(id)
        let tempData = {}
        Object.keys(res.data).forEach((key) => {
            if (typeof res.data[key] === 'boolean') tempData[key] = res.data[key]
            else tempData[key] = String(res.data[key])
        })
        detail.value = tempData
        detail.value.isActive = detail.value.status === "1" || detail.value.status === 1
    }

    return {detail, getDetail}
}
