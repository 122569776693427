<template>
  <div>
    <Toolbar v-if="create" class="tw-mb-8 tw-p-3 d-flex" v-show="!isMobile">
      <template v-if="buttonStyle" #end>
        <Button
          v-if="!isMobile"
          label="Tambah"
          icon="pi pi-plus"
          :class="['p-button-sm', 'p-button-success']"
          @click="$emit('new')"
        />
      </template>
      <template v-else #end>
        <Button
          v-if="!isMobile"
          label="Tambah"
          icon="pi pi-plus"
          class="p-button-sm bg-sky-500"
          @click="$emit('new')"
        />
      </template>
    </Toolbar>

    <div class="tw-flex tw-justify-center tw-my-4" v-show="isMobile">
      <Button
        :label="title_services"
        icon="pi pi-plus"
        class="tw-mt-0 p-button-text tw-text-blue-500 tw-font-medium"
        @click="$emit('new')"
      />
    </div>

    <div v-show="!isMobile">
      <DataTable :value="data" responsiveLayout="scroll">
        <template #header>
          <slot name="header" />
        </template>

        <div v-if="columns.length > 0">
          <Column
            v-for="column in columns"
            :key="column.field"
            :field="column.field"
            :header="column.header"
          >
            <template v-if="column.process" #body="slotProps">
              {{ column.process(slotProps.data[column.field]) }}
            </template>
          </Column>
          <slot name="column" />
        </div>
        <template #empty>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
          >
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicTable",
  props: {
    data: Array,
    title_services: String,
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    create: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Reactive data property for screen width
      isMobile: window.innerWidth <= 768,
    };
  },
  methods: {
    // Method to update isMobile based on current window size
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  mounted() {
    // Add event listener for resize event when component is mounted
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // Clean up event listener when component is unmounted
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped></style>
