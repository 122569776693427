<template>
  <div class="tw-p-6 md:tw-p-2 tw-drop-shadow-md tw-border-b-2">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-6 tw-ml-3">
      <router-link
        :to="{ name: 'barbers' }"
        class="text-lg tw-mt-3 tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
      >
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <Button
        icon="pi pi-pencil"
        label="Edit Pegawai"
        class="tw-h-11 tw-text-sm tw-mt-3 tw-mr-3 tw-bg-transparent tw-text-black tw-border-gray-300"
        @click="isEditDetail = true"
      />
    </div>
    <h5 class="tw-text-2xl inter-semibold tw-text-black tw-ml-3">
      Detail Pegawai
    </h5>
  </div>

  <div class="grid tw-p-10 tw-justify-between">
    <div class="col-12 md:col-4 card" style="height: 30rem">
      <div class="grid tw-justify-between p-2">
        <div class="p-col">
          <h1 style="font-size: 20px; font-weight: bold">{{ data.name }}</h1>
          <p style="font-size: 16px">{{ data.salon_name }}</p>
        </div>
        <div class="p-col">
          <Avatar
            :label="initial.value"
            class="mr-2"
            size="large"
            style="background-color: #dedede; color: #2196f3; font-weight: bold"
            shape="circle"
          />
        </div>
      </div>
      <div class="border-top-1">
        <div class="mt-3 ml-1">
          <BarberInfos
            :salon-name="data?.salon_name"
            :salon-id="data?.salon_id"
            :barber-id="route.params?.id"
          />
        </div>
        <div v-for="column in columns" :key="`_${column.field}`">
          <div class="col-5 md:col-4 opacity-60">
            {{ column.header }}
          </div>
          <div class="col-7 md:col-8 tw-font-medium">
            <div v-if="!column?.process">
              {{ data[column.field] }}
              <div class="mt-1">
                <Tag
                  class="mr-2 status status-warning tw-bg-green-100 tw-text-green-700"
                  value="Aktif"
                  v-if="data.status_name == 'Active'"
                ></Tag>
                <Tag
                  v-else-if="data.status_name == 'Non-Active'"
                  value="Tidak Aktif"
                  class="tw-bg-red-100 tw-text-red-700 tw-py-1 tw-px-2 tw-rounded-full tw-text-xs"
                >
                </Tag>
              </div>
            </div>
            <div v-else>
              {{ column.process(data[column.field]) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-8">
      <Dialog
        v-model:visible="isEditDetail"
        :modal="true"
        :showHeader="true"
        :baseZIndex="10000"
        :style="{ width: '40%' }"
      >
        <div>
          <BarberForm
            @submit="updateBarber"
            :initial-data="data"
            :is_create="false"
            :title="'Edit Pegawai'"
            @cancel="cancelDialog"
          />
        </div>
      </Dialog>

      <div class="tw-block sm:tw-hidden">
        <div
          class="card tw-p-0 tw-mb-5"
          v-for="(item, i) in barberServices"
          :key="`item_${i}`"
        >
          <div class="tw-px-5 tw-py-3">
            <div class="tw-flex tw-justify-between">
              <h6 class="tw-capitalize tw-mb-3 tw-pt-1">Layanan Pegawai</h6>
              <a href="#" @click.prevent="toogle($event, item)">
                <i class="pi pi-ellipsis-v"></i>
              </a>
              <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
            </div>
            <hr class="mb-3" />
            <div class="tw-flex tw-justify-between tw-mb-1">
              <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">
                Nama Layanan
              </h6>
              <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
                {{ item.service_name }}
              </h6>
            </div>
            <div class="tw-flex tw-justify-between tw-mb-1">
              <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">Harga</h6>
              <h6 class="tw-capitalize tw-text-sm ellipsis">
                {{ formatCurrency(item.price) }}
              </h6>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <BarberServices
        :salon-name="data?.salon_name"
        :salon-id="data?.salon_id"
        :barber-id="route.params?.id"
        @getBarberServices="getBarberServices"
      />

      <br />
      <div class="tw-block sm:tw-hidden">
        <div
          class="card tw-p-0 tw-mb-5"
          v-for="(item, i) in barberAvailabilities"
          :key="`item_${i}`"
        >
          <div class="tw-px-5 tw-py-3">
            <div class="tw-flex tw-justify-between">
              <h6 class="tw-capitalize tw-mb-3 tw-pt-1">Layanan Pegawai</h6>
              <a href="#" @click.prevent="toogle($event, item)">
                <i class="pi pi-ellipsis-v"></i>
              </a>
              <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
            </div>
            <hr class="mb-3" />
            <div class="tw-flex tw-justify-between tw-mb-1">
              <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">
                Nama Layanan
              </h6>
              <h6 class="tw-capitalize opacity-60 tw-text-sm ellipsis">
                {{ item.service_name }}
              </h6>
            </div>
            <div class="tw-flex tw-justify-between tw-mb-1">
              <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-5">Harga</h6>
              <h6 class="tw-capitalize tw-text-sm ellipsis">
                {{ formatCurrency(item.price) }}
              </h6>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <BarberAvailabilities
        :salon-name="data?.salon_name"
        :salon-id="data?.salon_id"
        :barber-id="route.params?.id"
        :barberServices="barberServices"
      />
    </div>
  </div>
</template>

<script setup>
import BarberServices from "./BarberServices";
import BarberAvailabilities from "./BarberAvailabilities";
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";
import { onBeforeMount, ref } from "vue";
import { useBarberForm } from "@/composables/barber";
import BarberInfos from "./BarberInfos";
import BarberForm from "./Form";
// import { useSalonServiceDropdown } from "@/composables/salon_service";
// const { salonServices } = useSalonServiceDropdown();

const data = ref({});
const barberServices = ref([]);
const barberAvailabilities = ref([]);
const route = useRoute();
const toast = useToast();
const { getDetail, update } = useBarberForm();
const isEditDetail = ref(false);
// const formData = ref({});
const columns = [
  // { field: "salon_name", header: "Nama Salon" },
  // { field: "name", header: "Nama Pegawai" },
  // { field: "position", header: "Posisi" },
  {
    field: "gender",
    header: "Jenis Kelamin",
    process: (data) => (data === "M" ? "Pria" : "Wanita"),
  },
  {
    field: "hide_rating",
    header: "Rating",
    process: (data) => (data ? "Tampil" : "Tidak Tampil"),
  },
  { field: "status_name", header: "Status" },
];

const cancelDialog = () => {
  // Set isEditDetail menjadi false ketika tombol "Batal" diklik
  isEditDetail.value = false;
};

const updateBarber = async (dataParams) => {
  await update(route.params.id, dataParams);
  isEditDetail.value = false;
  data.value = await getDetail(route.params.id);
  toast.add({
    severity: "success",
    summary: "Edit Pegawai Berhasil",
    life: 3000,
  });
};

// const getBarberServices = (data) => {
//   barberServices.value = data.value
// };
const getBarberServices = (data) => {
  barberServices.value = [];

  data.value.forEach((service) => {
    barberServices.value.push(service);
  });
};

const initial = (data) => {
  const words = data.split(" ");
  let initials = "";

  for (const word of words) {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  }

  return initials;
};

const formatCurrency = (data) => {
  let value = parseInt(data);
  return value.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
};
onBeforeMount(async () => {
  data.value = await getDetail(route.params.id);
  initial.value = initial(data.value.name);
  // this.getBarberServices();
});
</script>

<style scoped></style>
