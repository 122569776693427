import axios from "axios";

const SALON_SERVICE_URL = 'api/v1/services'

export default class SalonServiceService {
    getAll(queryParam) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}/list`, {params: queryParam}).then(res => res.data)
    }

    getDropdown(queryParam) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}/ddl`, {params: queryParam}).then(res => res.data)
    }

    create(formData) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}`, formData).then(res => res.data)
    }

    getOne(id) {
        return axios.get(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}/${id}`).then(res => res.data)
    }

    uploadImage(id, formData) {
        return axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}/${id}/image`, formData)
    }

    update(id, data) {
        return axios.put(`${process.env.VUE_APP_PRITI_MAIN_API}/${SALON_SERVICE_URL}/${id}`, data).then(res => res.data).catch(e => e.response.data)
    }
}
