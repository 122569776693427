<template>
  <div class="grid sm:align-items-center mb-2">
    <div class="col-5 md:col-10 opacity-60">Nomor Induk Kepegawaian</div>

    <div class="col-7 md:col-8 tw-font-medium">
      <div v-for="(info, key) in barberInfos" :key="`info_${key}`">
        <div
          v-if="info.info_name === 'NIK'"
          class="block sm:flex align-items-center"
        >
          {{ info.info_value }}
        </div>
      </div>

      <div v-if="!isNIKExist()">
        <Button
          icon="pi pi-plus"
          label="Tambah"
          class="p-button p-button-success"
          @click="onNew()"
        />
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Barber Informations"
    :modal="true"
    @hide="dismissModal"
  >
    <div class="p-fluid">
      <FormKit
        type="form"
        id="barber-info"
        v-model="formData"
        :actions="false"
        :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
        @submit="submitInfo"
      >
        <FormKit
          name="info_name"
          type="text"
          label="Name"
          placeholder="Masukkan nama informasi"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikListClass"
        />
        <FormKit
          name="info_value"
          type="text"
          label="Value"
          placeholder="Masukkan value informasi"
          validation="required"
          validation-visibility="dirty"
          :classes="defaultFormikListClass"
        />
      </FormKit>
    </div>
    <template #footer>
      <div class="w-full flex justify-content-between">
        <div>
          <Button
            label="Batal"
            class="p-button-danger"
            @click="dismissModal"
          />
        </div>
        <div>
          <Button label="Simpan" class="p-button-info" @click="submit" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { useBarberInfo } from "@/composables/barber";
import { defineProps, onMounted, ref } from "vue";
import { defaultFormikListClass } from "@/constants/formik";
import { reset, submitForm } from "@formkit/vue";

const props = defineProps({
  salonName: String,
  salonId: String,
  barberId: String,
});
const formData = ref({
  info_name: "",
  info_value: "",
});
const showDialog = ref(false);
const isEdit = ref(false);
const barberInfoId = ref(null);

const { barberInfos, getBarberInfos, create, update } = useBarberInfo(
  props.barberId
);

const onNew = () => {
  showDialog.value = true;
  reset("barber-info");
};

const dismissModal = () => {
  showDialog.value = false;
  isEdit.value = false;
  reset("barber-info");
};


const submit = async () => {
  submitForm("barber-info");
};

const submitInfo = async (data) => {
  const formData = {
    ...data,
    barber_id: parseInt(props.barberId),
  };
  if (isEdit.value) await update(barberInfoId.value, formData);
  else await create(formData);
  await getBarberInfos(props.salonName);
  dismissModal();
  isNIKExist();
};

const isNIKExist = () => {
  let obj = {};
  barberInfos.value.forEach((info) => {
    obj[info.info_name] = info.info_value;
  });

  return obj["NIK"];
};

onMounted(async () => {
  await getBarberInfos(props.salonName);
  isNIKExist();
});
</script>

<style scoped></style>
